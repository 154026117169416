@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  display: none;
}

html {
  /* Firefox Fix */
  overflow: auto;
  -ms-overflow-style: none;
  overscroll-behavior: none
}

body {
  margin: 0;
  font-family: "Microsoft Sans Serif", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  max-width: flex;
}

.background {
  background-color: rgb(4, 4, 4);
  color: orange;
}

h2 {
  font-size: 2rem;
}

.header,
.about,
.projects,
.experience,
.social,
.footer {
  padding: 2rem;
  font-family:Frutiger;
}

/* ------------------------------------------------------ */
/* -- Banner -- */

#rd {   
  display: flex;
  height: 10rem;
  width: 100%;
  background: url(./assets/matrixrain.gif) center repeat-x;    
  background-size: auto 100%;    
  position: relative;
  display: inline-block;

  flex-wrap: nowrap;

}

#rd:after {
  position: absolute;
  bottom: 0;  
  height: 100%;
  width: 100%;
  content: "";
  background: linear-gradient(to top,
     rgb(4, 4, 4), 
     rgba(255,255,255, 0) 99%
  );
  pointer-events: none; /* so the text is still selectable */
}

/* ------------------------------------------------------ */
/* -- Header -- */

.header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  text-align: center;
}

.header__content {
  width: 1700px;
}

.header__content h1 {
  font-size: 2rem;
  margin-bottom: 5vh;
}

.header__content .button {
  margin: 1rem;
  padding: 1rem;
  width: 15rem;
}

/* ------------------------------------------------------ */
/* -- About -- */

.about p {
  font-size: 18px;
  line-height: 130%;
  max-width: auto;
}

/* ------------------------------------------------------ */
/* -- Projects -- */

.projects__container {
  max-width: 200px;
  width: auto;
  align-items: center;
  flex-wrap: wrap;
}

.projects__row {
  display: inline-block;
  padding: 1rem;
}

.projects__card {
  display: flex;
  flex-direction: column;
  width: 70vw;
  padding: 1rem;
  background-color: rgb(0, 0, 0);
  transition: box-shadow 2s;
  will-change: transform; 
  border: 1px solid orange;
  margin-bottom: 1rem;
}

.projects__card-title {
  color: orange;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
}

.projects__card-body {
  color: orange;
  line-height: 1.5;
  font-size: 1rem;
  padding: 1rem;
}

.projects__card img {
  width:80%;
  height: auto;

  margin-top: 2%;

  border: 1px solid orange;
  align-self: center;
}

.projects__card .button {
  margin: 1rem auto 0;
  padding: 1rem;
  width: 10rem;
}

/* ------------------------------------------------------ */
/* -- Social Media -- */

.social .button {
  margin: 1rem 2rem 1rem 0rem;
  padding: 1rem;
  width: 10rem;
}

/* ------------------------------------------------------ */
/* -- Footer -- */
.footer {
  text-align: center;
}

/* ------------------------------------------------------ */
/* -- HTML COMPONENTS: BUTTON -- */

.button {
  display: inline-block;

  background: #001cf4;
  color: #7785f1;

  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;

  cursor: pointer;

  transition: all 250ms ease;

  text-align: center;

}

.button:hover {
  background: red;
  color: #911515;
}